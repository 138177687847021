import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import breading from '../../../assets/hair2.png'
import mechanic from '../../../assets/mechanic-servicing-car.jpg'
import tailor from '../../../assets/closeup-shot-black-female-smiling-her-phone-with-fabrics-front-her.jpg'
import visa from '../../../assets/kola.jpg'
import cloth from '../../../assets/fabricate-silk-cloth-shop-shelf-multicolor-many-pattern-rolled-silk-market-tbilisi.png'
import art from '../../../assets/people-antique-store-side-view.jpg'
import food from '../../../assets/food.jpg'
import food2 from '../../../assets/food2.jpg'
import amala from '../../../assets/amala.jpg'
import man from '../../../assets/portrait-african-gay-couple-looking-showcase-two-young-men-doing-shopping-clothing-store-standing-thoughtful-choosing-things-buy-spending-time-together-lgbt-couples-relations-concept.jpg'
import handy from '../../../assets/Handy_man_1.jpg'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Products = () => {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const settings ={
    dots:false,
    Infinite:true,
    fade:true,
    arrows:false,
    slidesToShow:1,
    slidesToScroll:1,
    autoplay:true,
    speed:1000,
    autoplaySpeed:7000,
    cssEase:'linear'
  }
  return (
   <>
   <Box>
    <Box sx={{margin:'0 auto', width:{lg:'85%', md:'85%', sm:'90%', xs:'90%'}, my:8}}>
<Typography variant='h3' sx={{fontSize:{lg:'40px', md:'40px', sm:'30px', xs:'30px'}}}>Enlist African goods and services</Typography>
    </Box>

    {
      isMobile ?
      <>
      <Box>
        <Grid container sx={{}} spacing={1}>
          <Grid item xs={5} sx={{}}>
          <Box sx={{ height:'100px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
            <Avatar src={breading} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={mechanic} variant='square' sx={{width:'100%', height:'100%'}}/>
        
            </Slider>
          </Box>
          </Grid>
          <Grid item xs={7} sx={{}}>
          <Box sx={{ height:'100px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
            <Avatar src={cloth} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={art} variant='square' sx={{width:'100%', height:'100%'}}/>

      
            </Slider>
          </Box>
          </Grid>
          <Grid item xs={7} sx={{}}>
          <Box sx={{ height:'100px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
            {/* <Avatar src={food} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={food2} variant='square' sx={{width:'100%', height:'100%'}}/> */}
                  <Avatar src={food2} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={amala} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={visa} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
          </Grid>
          <Grid item xs={5} sx={{}}>
          <Box sx={{ height:'100px', mt:-2, overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
          <Avatar src={handy} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={tailor} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
          </Grid>
          <Grid item xs={3} sx={{}}>
         
          </Grid>
          <Grid item xs={9} sx={{}}>
          <Box sx={{mt:-4, height:'100px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
          <Avatar src={food} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={man} variant='square' sx={{width:'100%', height:'100%'}}/>
            {/* <Avatar src={mechanic} variant='square' sx={{width:'100%', height:'100%'}}/> */}
            {/* <Avatar src={visa} variant='square' sx={{width:'100%', height:'100%'}}/> */}
            {/* <Avatar src={tailor} variant='square' sx={{width:'100%', height:'100%'}}/> */}
            </Slider>
          </Box>
          </Grid>
        </Grid>
      </Box>
      </>
      :
      <Box>
      <Grid container spacing={2} sx={{display:'flex', }}>
        <Grid item lg={5} xs={5}  >
          <Box sx={{ height:'350px', overflow:'hidden',bgcolor:'#000', boxShadow:' rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}}>
          <Slider {...settings} >
          <Avatar src={breading} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={mechanic} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
        </Grid>
        <Grid item lg={7} xs={7} >
          <Box sx={{ height:'300px', overflow:'hidden',  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
          <Slider {...settings} >
          <Avatar src={cloth} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={art} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
        </Grid>
        <Grid item lg={7} xs={7}  >
          <Box sx={{ height:'300px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
          <Avatar src={food2} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={amala} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={visa} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
        </Grid>
        <Grid item lg={5} xs={5} >
          <Box sx={{ height:'300px', mt:-10, overflow:'hidden',  boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
          <Avatar src={handy} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={tailor} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
        </Grid>
        <Grid item lg={2.5} >
          <Box sx={{height:'300px'}}></Box>
        </Grid>
        <Grid item lg={9.5} >
          <Box sx={{mt:-6, height:'300px', overflow:'hidden', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'}}>
          <Slider {...settings} >
          <Avatar src={food} variant='square' sx={{width:'100%', height:'100%'}}/>
            <Avatar src={man} variant='square' sx={{width:'100%', height:'100%'}}/>
            </Slider>
          </Box>
        </Grid>

      </Grid>
    </Box>
    }
 
   </Box>
   </>
  )
}

export default Products
