import { Button, Box, Typography, IconButton, InputLabel, TextField, Divider, Select, MenuItem } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Modal from '@mui/material/Modal';
import logo from '../../assets/Logo/logo.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GoogleIcon from '@mui/icons-material/Google';
import fetch from 'isomorphic-fetch';
import Loader from './Loader';
// import Mailchimp from 'mailchimp-api-v3';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from './CustomForm'



const show = process.env.REACT_APP_MAILCHIMP_U
console.log(show, '=>ENV')
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:{lg: 500, md:500, sm:350, xs:350},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const Signup = (props) => {

  // const mailchimp = new Mailchimp('225a3b1a36cbb544b1dfd5eb457f6e3c-us9');
const postUrl = `https://app.us9.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

    const [user, setUser] = React.useState(null);

    const [loading, setLoading] = useState(false)
    // const [userList, setUserList] = useState([])

    const [open, setOpen] = React.useState(false);



    const handleClose = () =>{
setUser(null)
     setOpen(false);
    }
const handleSubmitForm = ()=>{


    // mailchimp.post('/lists/879468fdf1/members', {
    //   email_address: 'user@example.com',
    //   status: 'subscribed',
    //   merge_fields: {
    //     FNAME: 'John',
    //     LNAME: 'Doe'
    //   }
    // }).then(response => {
    //   console.log('Successfully subscribed user!');
    // }).catch(error => {
    //   console.error('Error subscribing user:', error);
    // });

  }
    const handleChange = (event) => {
        setUser(event.target.value);
      };

      // async function postData() {
      //   setLoading(true)
      //   const url = 'https://dev-api.mamaket.com/graphql'; // Replace with your GraphQL API endpoint
      //   const query = `
      //   query {
      //     getUserTypes {
      //         key
      //         value
      //     }
      // }
      //   `;
        
      //   const options = {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       // Add any required headers for your API, such as authentication tokens
      //     },
      //     body: JSON.stringify({ query }),
      //   };
      
      //   try {
      //     const response = await fetch(url, options);
      //     const data = await response.json();
      //     if(data){
      //       setLoading(false)
      //     setUserList(data?.data?.getUserTypes)
          
      //     }
      //     console.log("Sam",userList)
      //     console.log(data?.data?.getUserTypes); // Process the response data here
      //   } catch (error) {
      //     console.error('Error:', error);
      //   }
      // }
      const handleOpen = () =>{
      
             setOpen(true);
            }

      
      
      
      

  return (
<>

<Button onClick={handleOpen}  sx={{background:props.color === "primary" ? "#208573" : '#fff', color:props.color === "primary" ? '#fff' : "#2f2f2f", borderRadius:'20px', py:1, px:3, '&:hover':{background:'#148B77', }}}>Join Mamaket</Button>

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
  
         <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
<img src={logo} width={90}  alt='logo'/>
<IconButton onClick={handleClose}>
<HighlightOffIcon/>
</IconButton>
         </Box>
         { loading && <Loader/> }
         <Box sx={{margin:'0 auto', width:'90%', mt:5}}>
          
         {
  user === null ?
         <Box>
  




                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                  }}
                >
               User Type
                </InputLabel>

                 <Select
                 fullWidth
          value={user}
          onChange={handleChange}
          sx={{borderRadius:'20px', mt:2,  color:'#717171', fontSize:'13px'}}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label',    style: {
            fontSize: "13px",
            borderRadius: "10px",
            offset: " 1px solid #737373",
            // Replace with your desired font family
          }, }}
        >
          <MenuItem  sx={{fontSize:'13px'}} value={null}>
           Please Select
          </MenuItem>


              <MenuItem  sx={{fontSize:'13px'}} value="Buyer">Buyer</MenuItem>
              <MenuItem  sx={{fontSize:'13px'}} value="Seller">Seller</MenuItem>
              <MenuItem  sx={{fontSize:'13px'}} value="Both">Both</MenuItem>


        </Select>


                </Box>
                :
                <>
                  <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                    user={user}
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
</>
}
            </Box>
            
            
        </Box>
      </Modal>
</>
  )
}

export default Signup
