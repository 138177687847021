import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ghana from "../../../assets/Cultures/ghana.svg";
import ivory from "../../../assets/Cultures/ivory.svg";
import jamaica from "../../../assets/Cultures/jamaica.svg";
import nigeria from "../../../assets/Cultures/nigeria.svg";
import south from "../../../assets/Cultures/south.svg";
import somalia from "../../../assets/Cultures/somalia.svg";
import uganda from "../../../assets/Cultures/uganda.svg";
import zimbabwe from "../../../assets/Cultures/zimbabwe.svg";
import kenya from "../../../assets/Cultures/kenya.svg";
import egypt from "../../../assets/Cultures/egypt.svg";
import morocco from "../../../assets/Cultures/morocco.svg";
import cameroon from "../../../assets/Cultures/cameroon.svg";
import rwanda from "../../../assets/Cultures/rwanda.svg";
import tunisia from "../../../assets/Cultures/tunisia.svg";
import tanzania from "../../../assets/Cultures/tanzania.svg";
import eritrea from "../../../assets/Cultures/eritrea.svg";
import Signup from '../Signup';

const cultureData = [
  {
    img: nigeria,
    caption: "Nigeria",
  },

  {
    img: ivory,
    caption: "Ivory Coast",
  },
  {
    img: jamaica,
    caption: "Jamaica",
  },

  {
    img: south,
    caption: "South Africa",
  },
  {
    img: somalia,
    caption: "Somalia",
  },
  {
    img: uganda,
    caption: "Uganda",
  },
  {
    img: ghana,
    caption: "Ghana",
  },
  {
    img: zimbabwe,
    caption: "Zimbabwe",
  },
  {
    img: kenya,
    caption: "Kenya",
  },
  {
    img: egypt,
    caption: "Egypt",
  },
  {
    img: morocco,
    caption: "Morocco",
  },
  {
    img: cameroon,
    caption: "Cameroon",
  },
  {
    img: rwanda,
    caption: "Rwanda",
  },
  {
    img: tunisia,
    caption: "Tunisia",
  },
  {
    img: tanzania,
    caption: "Tanzania",
  },
  {
    img: eritrea,
    caption: "Eritea",
  },
];

const cultureData2 = [
  {
    img: egypt,
    caption: "Egypt",
  },
  {
    img: tanzania,
    caption: "Tanzania",
  },
  {
    img: cameroon,
    caption: "Cameroon",
  },
  {
    img: rwanda,
    caption: "Rwanda",
  },
  {
    img: tunisia,
    caption: "Tunisia",
  },

  {
    img: zimbabwe,
    caption: "Zimbabwe",
  },
  {
    img: eritrea,
    caption: "Eritea",
  },
  {
    img: nigeria,
    caption: "Nigeria",
  },

  {
    img: ivory,
    caption: "Ivory Coast",
  },
  {
    img: jamaica,
    caption: "Jamaica",
  },

  {
    img: south,
    caption: "South Africa",
  },
  {
    img: somalia,
    caption: "Somalia",
  },
  {
    img: uganda,
    caption: "Uganda",
  },
  {
    img: ghana,
    caption: "Ghana",
  },
  {
    img: morocco,
    caption: "Morocco",
  },
  {
    img: kenya,
    caption: "Kenya",
  },

];


const Culture = () => {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const settings ={
    dots:false,
    Infinite:true,
    slidesToShow:5,
    slidesToScroll:1,
    autoplay:true,
    speed:5000,
    autoplaySpeed:5000,
    cssEase:'linear',
    arrows:false,
  }
  const settings2 ={
    dots:false,
    Infinite:true,
    slidesToShow:4,
    slidesToScroll:1,
    autoplay:true,
    speed:5000,
    autoplaySpeed:5000,
    cssEase:'ease-out',
    arrows:false,
    
  }
  const settingsMobile2 ={
    dots:false,
    Infinite:true,
    slidesToShow:2,
    slidesToScroll:1,
    autoplay:true,
    speed:5000,
    autoplaySpeed:5000,
    cssEase:'ease-out',
    arrows:false,
    
  }
  return (
<>
{
  isMobile ?
  <>
  <Box sx={{bgcolor:'#f7f7f7', py:5, borderBottom:'1px solid #000'}}>
<Box sx={{margin:'0 auto', width:'90%', }}>
  <Box sx={{display:'flex', justifyContent:'space-between'}}>
<Typography variant='h3' sx={{fontSize:'40px'}}>Culture</Typography>
<Signup color="primary"/>
</Box>
<Box sx={{mt:0, py:2  }}>


<Slider {... settingsMobile2}>
  {
    cultureData.map((item)=>(
      <Box sx={{ mt:4 }}>
        <Box sx={{bgcolor:'#fff', display:'flex', alignItems:'center', boxShadow:'68px 50px 34px rgba(0, 0, 0, 0.01), 38px 28px 28px rgba(0, 0, 0, 0.04), 17px 13px 21px rgba(0, 0, 0, 0.07), 4px 3px 12px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08)', p:1, borderRadius:'20px', width:{lg:'60%', md:'60%', sm:'40%', xs:'70%'}, height:'50px'}}>
        <img src={item.img} width={40} />
        <Typography variant='body1' sx={{ml:1, color:'#000', fontSize:'12px'}}>{item.caption}</Typography>
        </Box>
      </Box>
    ))
  }


</Slider>
<Slider {... settingsMobile2}>
  {
    cultureData2.map((item)=>(
      <Box sx={{ mt:4 }}>
        <Box sx={{bgcolor:'#fff', display:'flex', alignItems:'center', boxShadow:'68px 50px 34px rgba(0, 0, 0, 0.01), 38px 28px 28px rgba(0, 0, 0, 0.04), 17px 13px 21px rgba(0, 0, 0, 0.07), 4px 3px 12px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08)', p:1, borderRadius:'20px', width:{lg:'60%', md:'60%', sm:'40%', xs:'70%'}, height:'50px'}}>
        <img src={item.img} width={40} />
        <Typography variant='body1' sx={{ml:1, color:'#000', fontSize:'12px'}}>{item.caption}</Typography>
        </Box>
      </Box>
    ))
  }


</Slider>
</Box>
</Box>
</Box>
  </>
  :
<Box sx={{bgcolor:'#f7f7f7', py:5, borderBottom:'1px solid #000'}}>
<Box sx={{margin:'0 auto', width:'85%', }}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
<Typography variant='h3' sx={{fontSize:'40px'}}>Culture</Typography>
<Signup color="primary"/>
</Box>
<Box sx={{mt:5, py:2  }}>
<Slider {...settings}>
  {
    cultureData.map((item)=>(
      <Box sx={{ }}>
        <Box sx={{bgcolor:'#fff', display:'flex', alignItems:'center', boxShadow:'68px 50px 34px rgba(0, 0, 0, 0.01), 38px 28px 28px rgba(0, 0, 0, 0.04), 17px 13px 21px rgba(0, 0, 0, 0.07), 4px 3px 12px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08)', p:2, borderRadius:'20px', width:'60%', height:'50px'}}>
        <img src={item.img} alt={item.caption} width={60} />
        <Typography variant='body1' sx={{ml:1, color:'#000'}}>{item.caption}</Typography>
        </Box>
      </Box>
    ))
  }
</Slider>

<Slider {...settings2}>
  {
    cultureData2.map((item)=>(
      <Box sx={{ mt:4 }}>
        <Box sx={{bgcolor:'#fff', display:'flex', alignItems:'center', boxShadow:'68px 50px 34px rgba(0, 0, 0, 0.01), 38px 28px 28px rgba(0, 0, 0, 0.04), 17px 13px 21px rgba(0, 0, 0, 0.07), 4px 3px 12px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08)', p:2, borderRadius:'20px', width:{lg:'60%', md:'60%', sm:'40%', xs:'30%'}, height:'50px'}}>
        <img src={item.img} alt={item.caption} width={60} />
        <Typography variant='body1' sx={{ml:1, color:'#000'}}>{item.caption}</Typography>
        </Box>
      </Box>
    ))
  }


</Slider>
</Box>
</Box>
</Box>
}

</>
  )
}

export default Culture
