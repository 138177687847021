import React from "react";
import {useRoutes} from "react-router-dom";
import Waitlist from './Waitlist'

export function Routes(){
    return useRoutes([
        {
            path: '/', element: <Waitlist/>
        }
    ])
}
