
import './STYLES/main.css'
import { Routes } from './Routes';

function App() {
  console.log(process.env)
  return (
      <Routes/>
  );
}

export default App;
