import { Box, Button, Grid, Typography, useTheme, Avatar } from '@mui/material'
import React from 'react'
import '../../../STYLES/main.css'
import logo from '../../../assets/Logo/logoWhite.svg'
import img1 from '../../../assets/fabricate-silk-cloth-shop-shelf-multicolor-many-pattern-rolled-silk-market-tbilisi.png'
import img2 from '../../../assets/hair2.png'
import img3 from '../../../assets/people-antique-store-side-view.jpg'
import img4 from '../../../assets/mechanic-servicing-car.jpg'
import Signup from '../Signup'



const Banner = () => {
  const theme = useTheme()
  return (
<>
<Box sx={{ bgcolor:'#101010', pb:3}}>
  <Box className="banner_pattern" sx={{height:'100%'}}>
    <Box className="banner_map">
    <Box className="banner_backdrop" sx={{height:'100%'}}>

      <Box align="center" sx={{pt:5, }}>
        <img src={logo} alt="Mamaket_Logo" width={120}/>
      </Box>
      <Box  align="center" sx={{mt:{lg:15, md:14, sm:12, xs:8}, px:2}}>
        <Typography variant='h1' sx={{fontSize:{lg:'110px', md:'70px', sm:'40px', xs:'40px'}, mb:2}}>
        Looking to sell African  <br/>       things in the USA?
        </Typography>
 
<Signup />

        <Typography variant='h3' sx={{fontSize:{lg:'18px', md:'18px', sm:'15px', xs:'15px'}, mt:3, color:'#fff'}}>An African marketplace for buying, selling and celebrating culture</Typography>
      </Box>

      <Box sx={{bgcolor:theme.palette.primary.main, margin:'0 auto', width:{lg:'80%', md:'80%', sm:'95%', xs:'95%'}, height:{lg:'400px', md:'400px', sm:'auto', xs:'auto'}, borderRadius:{lg:'20px', md:'20px', sm:'10px', xs:'10px'}, mt:4, pt:{lg:15, md:15, sm:10, xs:4}, pb:2}}>
        <Box className="banner_background_hero" sx={{margin:'0 auto', width:'90%',  height:{lg:'500px', md:'500px', sm:'auto', xs:'auto'} }}>

<Grid container sx={{display:'flex', height:'100%', margin:'0 auto', width:'90%', position:'relative', top:{lg:-100, md:-100, xs:-30} }} spacing={2}>
  <Grid item lg={3} md={3} xs={6} >
  <Avatar  src={img1} sx={{border:{lg:'4px solid #148b77', md:'4px solid #148b77', sm:'4px solid #148b77', xs:'2px solid #148b77'}, height:'100%',boxShadow:' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', width:'100%', borderRadius:'10px'}}/>
  </Grid>
  <Grid item lg={3} md={3} xs={6} >
    <Avatar src={img2} sx={{border:{lg:'4px solid #fff', md:'4px solid #fff', sm:'4px solid #fff', xs:'2px solid #fff'},  height:'100%',boxShadow:' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', width:'100%', borderRadius:'10px'}}/>
  </Grid>
  <Grid item lg={3} md={3} xs={6} >
  <Avatar src={img3} sx={{border:{lg:'4px solid #148b77', md:'4px solid #148b77', sm:'4px solid #148b77', xs:'2px solid #148b77'}, height:'100%',boxShadow:' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', width:'100%', borderRadius:'10px'}}/>
  </Grid>
  <Grid item lg={3} md={3} xs={6} >
  <Avatar src={img4} sx={{border:{lg:'4px solid #fff', md:'4px solid #fff', sm:'4px solid #fff', xs:'2px solid #fff'},   height:'100%',boxShadow:' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', width:'100%', borderRadius:'10px'}}/>
  </Grid>


</Grid>

        </Box>

      </Box>
      </Box>
    </Box>

  </Box>

</Box>
</>
  )
}

export default Banner
