import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import cart from "../../../assets/cart.png";
import Aos from "aos";
import "aos/dist/aos.css";

const WhatWeProvide = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Box align="center" sx={{}}>
        <Typography
          variant="body1"
          sx={{ mt:{ lg:13, md:13, sm:10, xs:8}, color: "#000", width: {lg:"50%", md:'50%', sm:'70%', xs:'80%'}, textAlign: "center" }}
        >
You’re being invited to join Mamaket beta version. Mamaket is a marketplace for all African-made goods and services. We offer new online shopping features that put your business ahead of others in visibility and profitability in the US giving people of African decent a chance to enjoy their favorite African-made products.
        </Typography>
      </Box>
      <Box sx={{ margin: "0 auto", width: {lg:"85%", md:'85%', sm:'90%', xs:'90%'}, mt:{lg :10, md:10, sm:8, xs:5} }}>
        {
          isMobile ?
          <Typography variant="h3" sx={{fontSize:{lg:'64px', md:'64px', sm:'50px', xs:'30px'}}}>
          Why you should start
        with us!
        </Typography>
        :
        <Typography variant="h3" sx={{fontSize:{lg:'64px', md:'64px', sm:'50px', xs:'30px'}}}>
        Why you should start<br/>
      with us!
      </Typography>
        }
      
        <Grid container sx={{ mt: {lg:10, md:10, sm:8, xs:0}, mb:5 }} spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box data-aos="fade-up">
              <Typography variant="subtitle1" sx={{ mb: 1, color: "#db8b00" }}>
                Diverse selection of{" "}
                <span style={{ background: "#000", padding: "2px", marginRight:'2px' }}>
                  African
                </span>
                 products and services
              </Typography>
              <Typography variant="body1">
                We offer a wide range of African products and services from
                trusted brands and vendors in the US. As a seller you can upload
                used and new products that Africans enjoy.
              </Typography>
            </Box>
            <Box data-aos="fade-up" sx={{ mt: 8, ml: {lg:10, md:2} }}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: "#346827" }}>
                Hassle-free shopping experience
              </Typography>
              <Typography variant="body1">
                Shopping in our platform is easy and user-friendly. Using our
                chatting system, you can communicate with sellers and negotiate
                prices in a safe and secure environment.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={5.8} sm={12} xs={12} sx={{'& > img':{ml:{lg:-20, md:-20,sm:-10, xs:-11}}}}>
            <img src={cart} width="130%"  data-aos="zoom-in" />
          </Grid>
          <Grid item lg={3} md={3.2} sm={12} xs={12}>
            <Box data-aos="fade-right" sx={{ ml:{lg: -12, md:-12, xs:0}, mt: 8, pr: {lg:10, md:10, sm:0, xs:0} }}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: "#1b1d7f" }}>
                Secured payment platform
              </Typography>
              <Typography variant="body1">
                With Mamapurse, our e-wallet system, both buyers and sellers
                don’t need credit or debit cards to have a safe and secure
                transaction process and a transparent return and exchange
                policy, so you can shop with confidence.
              </Typography>
            </Box>
            <Box data-aos="fade-right" sx={{ ml:{lg: -7,md:-7, sm:0, xs:0},  mt: 5, pr: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: "#e62f33" }}>
                Get your{" "}
                <span style={{ background: "#000", padding: "2px", marginRight:'2px' }}>
                  African
                </span>
                  brands to a wider audience in the US
              </Typography>
              <Typography variant="body1">
              We promote product and service visibility locally and also throughout the US. 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WhatWeProvide;
